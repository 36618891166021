(function () {
    'use strict';

    angular.module('lacus').controller('TesteController', TesteController);

    TesteController.$inject = [];

    function TesteController() {

        var teste = this;
        teste.teste = "Oiiii!!!!";

    }

})();