(function () {
    'use strict';

    angular.module('lacus').factory('EquipeService', EquipeService);

    EquipeService.$inject = [];

    function EquipeService() {

        return [
            {

                "name": "Luiz Henrique de Castro Carvalho",
                "role": "Diretor  Presidente",
                "image": "luiz.png",
                "activities": [
                    "Engenheiro Eletricista pela UFMG, MBA em Gestão de Negócios e Tecnologia da Informação pela Fundação Getúlio Vargas;",
                    "Atuou no CPQD - Centro de Pesquisas e Desenvolvimento da Telebrás e na CEMIG, onde por 31 anos ocupou os seguintes cargos: Adjunto de Divisão, Gerente de Divisão, Gerente de Departamento e Superintendente de Tecnologia da Informação, Superintendente de Logística e Aquisições, Assessor da Presidência da Gasmig e, de volta à CEMIG entre 2008 e 2015 Diretor de Geração e Transmissão."
                ]
            },
            {
                "name": "José Raimundo Dias Fonseca",
                "role": "Membro do Conselho de Administração",
                "image": "jose.png",
                "activities": [
                    "Engenheiro Eletricista, especializado em Management of Eletric Power Utilities, Engenharia de Manutenção e em Gestão Estratégia de Negócios;",
                    "Entre 1981 e 2015 atuou nas áreas de Distribuição, Transmissão, Geração e Comercial da CEMIG, onde",
                    "Exerceu cargos gerenciais até se aposentar em janeiro de 2015 como Diretor Comercial."
                ]
            }
        ];

    }

})();