(function () {
    'use strict';

    angular.module('lacus').controller('LoginFormController', LoginFormController);

    LoginFormController.$inject = ['$mdDialog', 'AuthService'];

    function LoginFormController($mdDialog, AuthService) {

        var loginform = this;
        loginform.$mdDialog = $mdDialog;
        loginform.auth = AuthService;
        loginform.erro = null;

        loginform.entrar = function (form) {

            if (form.$invalid) {
                loginform.erro = 'Dados Inválidos!';
                return;
            } else {
                loginform.erro = null;
            }

            loginform.auth.$signInWithEmailAndPassword(loginform.email, loginform.senha)
                .then(function (user) {
                    $mdDialog.hide({ user: user });
                })
                .catch(function (erro) {
                    $mdDialog.cancel(erro);
                });

        };
    }
})();