(function () {
    'use strict';

    angular.module('lacus').controller('ServicosController', ServicosController);

    ServicosController.$inject = ['ServicosService'];

    function ServicosController(ServicosService) {
        var servicos = this;

        servicos.content = ServicosService;
    }

})();