(function () {
    'use strict';

    angular.module('lacus').factory('ObjectService', ObjectService);

    ObjectService.$inject = [
        '$firebaseObject'
    ];

    function ObjectService(
        $firebaseObject
    ) {

        return $firebaseObject.$extend({
            toJSON: function toJSON() {
                //var temp = angular.copy(this);
                var temp = copy(this);
                temp.toJSON = null;
                temp = removeIrrelevantProps(temp);
                return JSON.parse(JSON.stringify(temp));
            },
            $$updated: function (snap) {
                // apply the changes using the super method
                var changed = $firebaseObject.prototype.$$updated.apply(this, arguments);
                fillDates(this);
                return changed;
            }
        });

    }

    function copy(obj) {
        var copyObj = {};

        for (var key in obj) {
            if (!obj.hasOwnProperty(key) ||
                key.indexOf('$') !== -1) {
                continue;
            }

            copyObj[key] = JSON.parse(JSON.stringify(obj[key]));
        }

        return copyObj;
    }

    function fillDates(data) {
        for (var key in data) {
            if (!data.hasOwnProperty(key) || key.indexOf('$') !== -1) {
                continue;
            }

            if (angular.isObject(data[key])) {
                fillDates(data[key]);
            } else if (typeof data[key] === "string" && /\d{4}-\d{2}-\d{2}/.test(data[key])) {
                data[key] = new Date(data[key]);
            }
        }
    }

    function removeIrrelevantProps(obj) {
        for (var prop in obj) {
            if (!obj.hasOwnProperty(prop) || prop.indexOf('$') === 0) {
                delete obj[prop];
            }

            if (obj[prop] instanceof Object) {
                removeIrrelevantProps(obj[prop]);
            }
        }

        return obj;
    }

})();