(function () {
    'use strict';

    angular.module('lacus').config(config);

    config.$inject = ['$mdThemingProvider'];

    function config($mdThemingProvider) {

        if (location.href.indexOf('lacusenergia') !== -1) {
            $mdThemingProvider.theme('default')
                .primaryPalette('blue')
                .accentPalette('deep-orange');
        } else {
            $mdThemingProvider.theme('default')
                .primaryPalette('red')
                .accentPalette('green');
        }

    }

})();