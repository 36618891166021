(function () {
    'use strict';

    angular.module('lacus').factory('UsersService', UsersService);

    UsersService.$inject = [
        'ObjectService'
    ];

    function UsersService(
        ObjectService
    ) {
        var obj = null;

        return function (uid) {
            var ref = 'users';

            if (uid) {
                ref += '/' + uid;

                if (obj && obj.uid === uid) {
                    return obj;
                }
            }

            obj = new ObjectService(firebase.database().ref(ref));

            return obj;
        };
    }

})();