(function () {
    'use strict';

    angular.module('lacus').factory('CreateUserService', CreateUserService);

    CreateUserService.$inject = ['UsersService'];

    function CreateUserService(UsersService) {

        return function (auth) {

            var user = UsersService(auth.user.uid);

            return user.$loaded()
                .then(function () {
                    user.displayName = auth.user.displayName;
                    user.email = auth.user.email;
                    user.uid = auth.user.uid;
                    return user.$save();
                })
                .catch(function (error) {
                    console.log('Deu erro', error);
                });
        };

    }

})();