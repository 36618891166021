(function () {
    'use strict';

    angular.module('lacus').service('TempCopyService', TempCopyService);

    function TempCopyService() {

        //Empty service to retain ramdom state

    }

})();