(function () {
    'use strict';

    angular.module('lacus').controller('ContatoController', ContatoController);

    ContatoController.$inject = [
        '$http',
        'DialogService'
    ];

    function ContatoController(
        $http,
        DialogService
    ) {

        var contato = this;
        contato.mensagem = {};
        contato.mensagem.nome = null;
        contato.mensagem.email = null;
        contato.mensagem.telefone = null;
        contato.mensagem.text = null;

        contato.enviarMensagem = function (form, ev) {

            if (form.$invalid) {
                alert('Formulário inválido');
                return;
            }

            return $http.post('/mensagem', contato.mensagem).then(function (resposta) {
                console.log(resposta);
                DialogService.alert('Sucesso', 'Mensagem enviada com sucesso', ev);

                //Reseta o formulário
                contato.mensagem = {};
                resetForm(form)
                console.log(form);
            }).catch(function (error) {
                console.log(error);
            });
        };

        function resetForm(form) {
            form.$setPristine();
            form.$setUntouched();
            angular.forEach(form, function (value, key) {
                if (key.indexOf('$') === 0) return;
                form[key].$setPristine();
                form[key].$setUntouched();
            });
        }
    }

})();