(function () {
    'use strict';

    angular.module('lacus').controller('RegistroController', RegistroController);

    RegistroController.$inject = [
        'AuthService',
        'DialogService',
        '$state',
        'CreateUserService'
    ];

    function RegistroController(AuthService, DialogService, $state, CreateUserService) {

        var registro = this;
        registro.auth = AuthService;
        registro.usuario = {};

        registro.registrar = function (form, ev) {
            if (form.$invalid ||
                registro.usuario.senha !== registro.usuario.senhaconfirmacao) {
                return DialogService.alert('Dados inválidos', 'Os dados informados não são válidos!');
            }

            registro.auth.$createUserWithEmailAndPassword(registro.usuario.email, registro.usuario.senha)
                .then(function (user) {
                    return user.updateProfile({
                        displayName: registro.usuario.nome
                    });
                })
                .then(function (resposta) {
                    return CreateUserService(AuthService);
                })
                .then(function (resposta) {
                    return DialogService.alert('Sucesso', 'Usuário criado com sucesso.');
                })
                .then(function () {
                    $state.go('areacliente');
                })
                .catch(function (erro) {
                    return DialogService.alert(
                        erro.code || 'Ocorreu um erro',
                        erro.message || 'Ocorreu um inesperada durante a criação do usuário.');
                });
        };

    }

})();