(function () {
    'use strict';

    angular.module('lacus').controller('AdminUploadController', AdminUploadController);

    AdminUploadController.$inject = ['$mdDialog', 'user', 'FileService'];

    function AdminUploadController($mdDialog, user, FileService) {
        var adminUpload = this;
        adminUpload.user = user;
        adminUpload.file = {};
        adminUpload.$mdDialog = $mdDialog;

        adminUpload.save = function (form) {

            if (form.$invalid) return;

            var file = document.querySelector('#file');
            if (!file.files.length) return;

            adminUpload.file.id = adminUpload.file.name + new Date().getTime();

            var storage = FileService(user.uid, adminUpload.file.id);

            angular.forEach(file.files, function (item) {
                var uploadTask = storage.$put(item);
                uploadTask.$complete(function (resposta) {
                    adminUpload.file.date = adminUpload.file.date.toJSON();
                    adminUpload.file.url = resposta.downloadURL;
                    $mdDialog.hide(adminUpload.file);
                });
                uploadTask.$error(function (error) {
                    console.log(error);
                });
            });

        };

    }

})();