(function () {
    'use strict';

    angular.module('lacus').controller('ClientesController', ClientesController);

    ClientesController.inject = [];

    function ClientesController() {
        var clientes = this;
        clientes.title = "Página de Clientes";

    }

})();