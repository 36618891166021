(function () {
    'use strict';

    angular.module('lacus').controller('SelectReferenciaController', SelectReferenciaController);

    SelectReferenciaController.$inject = [
        '$mdDialog'
    ];

    function SelectReferenciaController(
        $mdDialog
    ) {

        var selectReferencia = this;

        /////////////////////////
        //Propriedades privadas//
        /////////////////////////
        var offset = 0;

        /////////////////////////
        //Propriedades públicas//
        /////////////////////////
        selectReferencia.referencia = getReferencia();

        ////////////////////
        //Métodos privados//
        ////////////////////
        function getReferencia() {
            var data = new Date();
            data.setDate(1);
            data.setMonth(data.getMonth() + offset);
            var mes = data.getMonth() + 1;
            var ano = data.getFullYear();
            return mes + '-' + ano;
        }

        ////////////////////
        //Métodos publicos//
        ////////////////////
        selectReferencia.ok = function () {
            $mdDialog.hide(selectReferencia.referencia);
        };

        selectReferencia.cancelar = function () {
            $mdDialog.cancel('Operação cancelada pelo usuário.');
        };

        selectReferencia.proximo = function () {
            offset++;
            selectReferencia.referencia = getReferencia();
        };

        selectReferencia.anterior = function () {
            offset--;
            selectReferencia.referencia = getReferencia();
        };

    }

})();