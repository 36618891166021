(function () {
    'use strict';

    angular.module('lacus').controller('HomeController', HomeController);

    HomeController.$inject = ['$timeout'];

    function HomeController($timeout) {

        var home = this;

        home.title = "Home Page";
        home.slides = [
            { src: 'img/all/Cidade Luz.jpg', title: 'Geração Distribuída', index: 0 },
            { src: 'img/all/20170920_144350.jpg', title: 'Ética e Transparência', index: 1 },
            { src: 'img/all/Eólico_Solar.jpg', title: 'Trabalho em Equipe', index: 2 },
            { src: 'img/all/Parque Eólico.jpg', title: 'Melhoria Contínua', index: 3 },
            { src: 'img/all/lampada-arvore.jpg', title: 'Responsabilidade Socioambiental', index: 4 }
        ];
    }

})();