(function () {

    angular.module('lacus').factory('ExportCsv', ExportCsv);

    ExportCsv.$inject = [
        '$mdDialog'
    ];

    function flattenObject (ob) {
        var toReturn = {};

        for (var i in ob) {
            if (!ob.hasOwnProperty(i)) continue;

            if ((typeof ob[i]) == 'object') {
                var flatObject = flattenObject(ob[i]);
                for (var x in flatObject) {
                    if (!flatObject.hasOwnProperty(x)) continue;

                    toReturn[i + '.' + x] = flatObject[x];
                }
            } else {
                toReturn[i] = ob[i];
            }
        }
        return toReturn;
    };

    function ExportCsv($mdDialog) {
        return function (ev, dadosOriginais, nomeArquivo) {
            nomeArquivo = nomeArquivo || 'export';
            var confirm = $mdDialog.confirm()
                .title('Exportar para CSV')
                .textContent('Deseja exportar todos os dados da tabela para CSV?')
                .ariaLabel('Exportar para CSV')
                .targetEvent(ev)
                .ok('Exportar')
                .cancel('Cancelar');

            $mdDialog.show(confirm).then(function () {
                var csvContent = "";
                var cabecalho = "";
                var first = true;

                var dados = angular.copy(dadosOriginais);
                dados = dados.map(flattenObject);

                dados.forEach(function (objeto, index) {

                    var dataString = "";
                    for (var key in objeto) {
                        if (key.indexOf('$') !== -1) {
                            continue;
                        }

                        if (first) {
                            cabecalho += key.replace("_", " ") + ";";
                        }

                        var value = angular.copy(objeto[key]);

                        if (value instanceof Date) {
                            value = value.toLocaleDateString();
                        }

                        dataString += value + ";";
                    }
                    if (first) {
                        cabecalho += "\r\n";
                    }
                    first = false;
                    csvContent += index < dados.length ? dataString + "\r\n" : dataString;

                });

                csvContent = cabecalho + csvContent;

                var blob = null;
                if (window.navigator.msSaveOrOpenBlob) {
                    blob = new Blob([decodeURIComponent(encodeURI(csvContent))], {
                        type: "text/csv;charset=utf-8;"
                    });
                    navigator.msSaveBlob(blob, nomeArquivo + '.csv');
                } else {

                    blob = new Blob([csvContent], {
                        type: "text/csv;charset=utf-8;"
                    });

                    var a = document.createElement('a');
                    a.href = window.URL.createObjectURL(blob);
                    a.target = '_blank';
                    a.download = nomeArquivo + '.csv';

                    document.body.appendChild(a);
                    a.click();
                }
            }, function () {

            });
        };
    }

})();