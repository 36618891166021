(function () {
    'use strict';

    angular.module('lacus').controller('FiliaisController', FiliaisController);

    FiliaisController.$inject = [
        '$mdDialog',
        '$state',
        'UsersService',
        'DialogService',
        'TempCopyService',
        'SelecaoReferenciaService',
        'ExportCsv'
    ];

    function FiliaisController(
        $mdDialog,
        $state,
        UsersService,
        DialogService,
        TempCopyService,
        SelecaoReferenciaService,
        ExportCsv
    ) {

        var filiais = this;

        filiais.user = null;

        function showFormDadosFatura(referencia) {
            return $mdDialog.show({
                templateUrl: 'formDadosFatura.html',
                controller: 'FormDadosFaturaController',
                controllerAs: 'formDadosFatura',
                fullscreen: true,
                locals: { user: filiais.user, referencia: referencia }
            });
        }

        filiais.excluir = function ($event, $index) {
            DialogService.confirm('Atenção', 'Tem certeza que deseja excluir essa instalação?', $event)
                .then(function () {
                    filiais.user.filiais.splice($index, 1);
                    return filiais.user.$save();
                })
                .then(function () {
                    DialogService.alert('Sucesso', 'Instalação Excluída com sucesso!', $event);
                })
                .catch(function (erro) {
                    DialogService.alert('Erro', 'Ocorreu um erro: ' + erro, $event);
                });
        };

        filiais.enviarDados = function (ev) {

            SelecaoReferenciaService(ev)
                .then(showFormDadosFatura)
                .then(function (retorno) {

                    filiais.user.faturas = filiais.user.faturas || {};
                    filiais.user.faturas[retorno.referencia] = retorno.faturas;

                    return filiais.user.$save();

                }).then(function () {
                    DialogService.alert('Sucesso', 'Fatura Incluída com sucesso');
                })
                .catch(function (erro) {
                    DialogService.alert(erro);
                });
        };

        filiais.editar = function ($event, $index) {
            filiais.addFilial($event, $index);
        };


        filiais.addFilial = function (ev, $index) {

            var filial = null;

            if ($index != undefined &&
                filiais.user.filiais) {
                filial = filiais.user.filiais[$index];
            }

            $mdDialog.show({
                templateUrl: 'formFilial.html',
                controller: 'FormFilialController',
                controllerAs: 'formFilial',
                targetEvent: ev,
                locals: {
                    filial: filial
                }
            }).then(function (filial) {
                filiais.user.filiais = filiais.user.filiais || [];

                if ($index != undefined) {
                    filiais.user.filiais[$index] = filial;
                } else {
                    filiais.user.filiais.push(filial);
                }
                filiais.user.$save()
                    .then(function () {
                        DialogService.alert('Sucesso', 'Filial salva com sucesso');
                    })
                    .catch(function (erro) {
                        DialogService.alert('Erro', erro);
                    });
            });
        };

        filiais.exportarCSV = function (ev) {
            ExportCsv(ev, filiais.user.filiais, 'instalacoes');
        };

        (function inicializar() {

            filiais.user = UsersService($state.params.uid);
            filiais.user.$loaded().then(function () {
                filiais.user.filiais = filiais.user.filiais || [];
                TempCopyService.instalacao = angular.copy(filiais.user.filiais[0]);

                // //===========================================
                // var user2 = UsersService("ilFDkLADwYfVBwA7Kwp4vIsomaI3");
                // user2.$loaded().then(function () {
                //     user2.filiais = angular.copy(filiais.user.filiais);
                //     user2.$save();
                // });
                // //===========================================
            });

        })();

    }

})();
