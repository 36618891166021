(function () {
    'use strict';

    angular.module('lacus').factory('SelecaoReferenciaService', SelecaoReferenciaService);

    SelecaoReferenciaService.$inject = [
        '$mdDialog'
    ];

    function SelecaoReferenciaService($mdDialog) {
        return function (ev) {
            return $mdDialog.show({
                templateUrl: 'selectReferencia.html',
                controller: 'SelectReferenciaController',
                controllerAs: 'selectReferencia',
                targetEvent: ev
            });
        };
    }

})();