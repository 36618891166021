(function () {
    'use strict';

    angular.module('lacus').controller('FormDadosFaturaController', FormDadosFaturaController);

    FormDadosFaturaController.$inject = [
        'user',
        '$mdDialog',
        'referencia',
        'FocusService',
        '$timeout'
    ];

    function FormDadosFaturaController(
        user,
        $mdDialog,
        referencia,
        FocusService,
        $timeout
    ) {

        console.log(user.faturas);

        var formDadosFatura = this;

        formDadosFatura.user = user;
        formDadosFatura.faturas = getFaturas();
        formDadosFatura.temAnterior = false;
        formDadosFatura.temProximo = false;
        formDadosFatura.referencia = referencia;

        function getFaturas() {

            if (user.faturas && user.faturas[referencia]) {
                return user.faturas[referencia];
            }

            var faturas = [];

            angular.forEach(user.filiais, function (filial) {
                faturas.push({
                    numeroInstalacao: filial.numeroInstalacao,
                    descricao: filial.numeroInstalacao + ' - ' + filial.nome,
                    referencia: formDadosFatura.referencia,
                    consumo: null,
                    compensada: null,
                    disponibilidade: null,
                    data: null
                });
            });

            return faturas;
        }

        var indexAtual = 0;
        moveFaturas(0);

        formDadosFatura.anterior = function () {
            FocusService('consumo');
            moveFaturas(-1);
        };

        formDadosFatura.proximo = function () {
            FocusService('consumo');
            moveFaturas(+1);
        };

        formDadosFatura.keyPress = function (e) {
            if (e.code !== 'Enter') {
                return;
            }

            if (formDadosFatura.temProximo) {
                formDadosFatura.proximo();
            } else {
                formDadosFatura.salvar();
            }

        };

        function moveFaturas(direcao) {
            indexAtual = indexAtual + direcao;
            formDadosFatura.temAnterior = indexAtual > 0;
            formDadosFatura.temProximo = indexAtual < formDadosFatura.faturas.length - 1;
            formDadosFatura.fatura = formDadosFatura.faturas[indexAtual];
        }

        formDadosFatura.salvar = function () {
            $mdDialog.hide({
                faturas: formDadosFatura.faturas,
                referencia: formDadosFatura.referencia
            });
        };

        formDadosFatura.cancelar = function () {
            $mdDialog.cancel('Operação cancelada pelo usuário.');
        };

        formDadosFatura.changeDate = function (data) {
            angular.forEach(formDadosFatura.faturas, function (fatura) {
                fatura.data = fatura.data || data;
            });
        };

        $timeout(function () {
            FocusService('consumo');
        }, 500);

    }

})();
