(function () {
    'use strict';

    angular.module('lacus').controller('PersonalInfoController', PersonalInfoController);

    PersonalInfoController.$inject = [
        'info',
        '$mdDialog'
    ];

    function PersonalInfoController(
        info,
        $mdDialog
    ) {

        var personalInfo = this;

        personalInfo.teste = 'Informações de equipe';
        personalInfo.info = info;

        personalInfo.fechar = function () {
            $mdDialog.hide();
        };
    }

})();