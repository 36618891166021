(function () {
    'use strict';

    angular.module('lacus').factory('CepService', CepService);

    CepService.$inject = [
        '$http'
    ];

    function CepService(
        $http
    ) {

        var urlApiCep = 'http://correiosapi.apphb.com/cep/';

        return function (cep) {
            return $http.get(urlApiCep + cep);
        };

    }

})();