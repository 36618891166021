(function () {
    'use strict';

    angular.module('lacus').controller('InstitucionalController', InstitucionalController);

    InstitucionalController.$inject = [
        'InstitucionalService',
        'EquipeService',
        '$mdDialog'
    ];

    function InstitucionalController(
        InstitucionalService,
        EquipeService,
        $mdDialog
    ) {

        var institucional = this;

        institucional.content = InstitucionalService;
        institucional.equipe = EquipeService;
        institucional.conselho = {
            titulares: [
                "JOSÉ RAIMUNDO DIAS FONSECA",
                "THIAGO MAGGI QUARTIERO",
                "JOSÉ LUIZ RAMOS RESENDE",
                "CARLOS EDUARDO TEIXEIRA PINHEIRO"
            ],
            suplentes: [
                "PATRÍCIA ARAÚJO BRANCO",
                "LUCIANO MAGGI QUARTIERO",
                "LUIZ FERNANDO RESENDE",
                "MÁRCIO DE MORAES REGO CORRÊA BARBOSA "
            ]
        };

        institucional.showDetails = function (ev, info) {
            $mdDialog.show({
                templateUrl: 'personalInfo.html',
                controller: 'PersonalInfoController',
                controllerAs: 'personalInfo',
                locals: { info: info },
                targetEvent: ev
            });
        };
    }

})();