(function () {
    'use strict';

    angular.module('lacus').controller('FaturasController', FaturasController);

    FaturasController.$inject = [
        '$state',
        'UsersService',
        'ExportCsv'
    ];

    function FaturasController(
        $state,
        UsersService,
        ExportCsv
    ) {
        var faturas = this;

        /////////////////////////
        //Propriedades privadas//
        /////////////////////////

        /////////////////////////
        //Propriedades públicas//
        /////////////////////////
        faturas.uid = $state.params.uid;
        faturas.referencia = $state.params.referencia;
        faturas.user = UsersService($state.params.uid);

        ////////////////////
        //Métodos privados//
        ////////////////////

        ////////////////////
        //Métodos publicos//
        ////////////////////
        faturas.gerarCSV = function (ev) {
            var listFaturas = angular.copy(faturas.user.faturas[faturas.referencia]);
            angular.forEach(listFaturas, function (fatura) {
                delete fatura.descricao;
            });
            ExportCsv(ev, listFaturas, 'Faturas');
        };

    }

})();