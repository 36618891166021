(function () {
    'use strict';

    angular.module('lacus').controller('UserConfigController', UserConfigController);

    UserConfigController.$inject = ['$state', 'UsersService', '$mdDialog', 'DialogService', '$q'];

    function UserConfigController($state, UsersService, $mdDialog, DialogService, $q) {
        var userConfig = this;
        var uid = $state.params.uid;
        userConfig.user = UsersService(uid);

        userConfig.user.$loaded().then(function () {
            userConfig.user.files = userConfig.user.files || [];
        });

        userConfig.sendFiles = function () {

            $mdDialog.show({
                templateUrl: 'adminUpload.html',
                controller: 'AdminUploadController',
                controllerAs: 'adminUpload',
                locals: {
                    user: userConfig.user
                }
            }).then(function (file) {
                userConfig.user.files.push(file);
                return userConfig.user.$save();
            }).then(function (resposta) {
                DialogService.alert('Sucesso', 'Arquivo salvo com sucesso');
            }).catch(function (erro) {
                DialogService.alert(erro);
            });
        };

        userConfig.deleteFile = function (index) {
            var storage = FileService(uid, userConfig.user.files[index].id);
            userConfig.user.files.splice(index, 1);
            var promises = [
                userConfig.user.$save(),
                storage.$delete()
            ];

            $q.all(promises).then(function (resposta) {
                DialogService.alert('Sucesso', 'Arquivo deletado com sucesso');
            }).catch(function (erro) {
                DialogService.alert('Erro', 'Ocorreu um erro: ' + erro);
            });
        };
    }

})();