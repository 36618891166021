(function () {
    'use strict';

    angular.module('lacus').controller('AreaClienteController', AreaClienteController);

    AreaClienteController.$inject = [
        'AuthService',
        '$state',
        'UsersService'
    ];

    function AreaClienteController(
        AuthService,
        $state,
        UsersService
    ) {

        var areacliente = this;

        areacliente.teste = 'area do cliente';
        areacliente.auth = AuthService;
        areacliente.user = null;

        areacliente.sair = function () {
            areacliente.auth.$signOut();
            $state.go('home');
        };

        areacliente.enviarDados = function (ev) {
            $state.go('filiais', { uid: areacliente.user.uid });
        };

        (function inicializar() {

            AuthService.getUser().then(function (user) {
                areacliente.user = UsersService(user.uid);
            }).catch(function () {
                $state.go('login', { route: 'areacliente' });
            });

        })();
    }

})();