(function () {
    'use strict';
    var dependencies = [
        'ngMaterial',
        'ngMessages',
        'firebase',
        'ui.router',
        'ui.carousel',
        'md.data.table'
    ];

    angular.module('lacus', dependencies)
        .run(config);

    config.$inject = ['$timeout', 'AuthService'];

    function config($timeout, AuthService) {
        var intro = document.querySelector(".intro");
        intro.classList.add("remove");
        $timeout(function () {
            intro.style.display = "none";
        }, 1000);
    }
})();