(function () {
    'use strict';

    angular.module('lacus').controller('LoginController', LoginController);

    LoginController.$inject = [
        'AuthService',
        '$mdDialog',
        'CreateUserService',
        'DialogService',
        '$state'
    ];

    function LoginController(
        AuthService,
        $mdDialog,
        CreateUserService,
        DialogService,
        $state
    ) {

        var login = this;
        login.auth = AuthService;

        if (login.auth.user) {
            CreateUserService(login.auth)
                .then(navigate);
        }

        login.entrar = function (provider) {
            login.auth.$signInWithPopup(provider)
                .then(CreateUserService)
                .then(navigate)
                .catch(handleError);
        };

        login.EntrarEmailSenha = function (ev) {
            $mdDialog.show({
                templateUrl: 'loginForm.html',
                controller: 'LoginFormController',
                controllerAs: 'loginform',
                targetEvent: ev,
                clickOutsideToClose: true
            })
                .then(CreateUserService)
                .then(navigate)
                .catch(handleError);
        };

        function handleError(erro) {
            if (!erro) return;
            return DialogService.alert(
                erro.code || 'Ocorreu um erro',
                erro.message || 'Ocorreu um inesperada durante a criação do usuário.'
            );
        }

        function navigate() {
            $state.go($state.params.route);
        }
    }

})();