(function () {
    'use strict';

    angular.module('lacus').factory('ServicosService', ServicosService);

    ServicosService.$inject = [];

    function ServicosService() {

        return [
            [
                {
                    "imagem": "PordoSol_Eólico1.jpg",
                    "content": "Análise da localização geográfica, consumo, nível de tensão, preço de energia e outros dados específicos do mercado cativo, de todos os pontos de consumo de energia do cliente, visando dimensionar e estruturar o atendimento;"
                },
                {
                    "imagem": "PordoSol_Eólico.jpg",
                    "content": "Dimensionamento e identificação da(s) unidade(s) geradora(s) capaz(es) de gerar créditos suficientes para atender à demanda de energia do cliente;"
                },
                {
                    "imagem": "document-428334_1280.jpg",
                    "content": "Fornecer todas as minutas dos instrumentos necessários para a operação, incluindo o contrato de transferência da posse da(s) unidade(s) geradora(s) de energia, de operação e manutenção e o contrato de gestão comercial e operacional;"
                }
            ],
            [
                {
                    "imagem": "Painel_Solar.jpg",
                    "content": "Assessorar e acompanhar o cliente nos contatos com as concessionárias de energia elétrica para que sejam cumpridos os trâmites e requisitos necessários à compensação de energia elétrica gerada pelas usinas indicadas;"
                },
                {
                    "imagem": "man-2140606_1280.jpg",
                    "content": "Gerir, acompanhar e conduzir a operação do cliente, prestando todo tipo de assessoria e fornecendo relatórios ou informações."
                }
            ]
        ];

    }

})();