(function () {
    'use strict';

    angular.module('lacus').controller('FormFilialController', FormFilialController);

    FormFilialController.$inject = [
        '$mdDialog',
        'CepService',
        '$timeout',
        'TempCopyService',
        'filial'
    ];

    function FormFilialController(
        $mdDialog,
        CepService,
        $timeout,
        TempCopyService,
        filial
    ) {

        var formFilial = this;
        var isWaitingCep = false;
        var WAITING_TIME = 1000;

        formFilial.filial = initFilial();

        function initFilial() {

            if (filial) {
                return angular.copy(filial);
            }

            if (TempCopyService.instalacao) {
                return angular.copy(TempCopyService.instalacao);
            }

            return {
                name: null,
                numeroCliente: null,
                numeroInstalacao: null,
                cnpj: null,
                razaoSocial: null,
                endereco: {
                    cep: null,
                    tipoDeLogradouro: null,
                    logradouro: null,
                    numero: null,
                    bairro: null,
                    cidade: null,
                    estado: null
                }
            };
        }

        formFilial.salvar = function (form) {
            if (form.$invalid) return;
            TempCopyService.instalacao = angular.copy(formFilial.filial);
            $mdDialog.hide(formFilial.filial);
        };

        formFilial.cancelar = function () {
            $mdDialog.cancel();
        };

        formFilial.GetAddress = function () {
            if (isWaitingCep) return;

            isWaitingCep = true;

            //$timeout(CepService, WAITING_TIME, true, formFilial.filial.endereco.cep)
            $timeout(function () {
                return CepService(formFilial.filial.endereco.cep);
            }, WAITING_TIME)
                .then(function (resposta) {
                    formFilial.filial.endereco = resposta.data;
                })
                .catch(function (erro) {
                    console.log(erro);
                })
                .finally(function () {
                    isWaitingCep = false;
                });
        };


    }

})();