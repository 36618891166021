(function () {
    'use strict';

    angular.module('lacus').factory('InstitucionalService', InstitucionalService);

    InstitucionalService.$inject = [];

    function InstitucionalService() {

        return [
            {
                "title": "Visão de Mercado",
                "imagem": "graph-163509_1280.jpg",
                "content": [
                    "•	A Lacus atua no segmento de geração distribuída de energia elétrica, priorizando fontes hídricas, solares e eólicas",
                    "•	A criação da Resolução Normativa ANEEL 482 de 2012 e suas alterações permitiu ao consumidor brasileiro gerar a própria energia a partir de fontes renováveis, considerando os limites de micro e minigeração",
                    "•	A autoprodução de energia correspondeu a aproximadamente 4,65% de toda a energia consumida no Brasil ",
                    "•	O excedente gerado pela fonte de energia renovável pode ser fornecido para a distribuidora de energia local em troca de crédito de energia. Esse saldo pode ser compensado com o uso futuro de energia fornecida pela distribuidora",
                    "•	Em vários estados do Brasil, o consumidor é tributado com o ICMS, PIS e COFINS apenas sobre o consumo que exceder à geração própria de energia",
                    "•	A geração distribuída incentiva a geração de energia a partir de fontes renováveis, aumentando a participação de fontes de energia limpa na Matriz Energética",
                    "•	O governo brasileiro busca incentivar a geração de energia renovável, o que traz benefícios ambientais, menos custos, mais segurança na geração, além da estabilidade de preços",
                    "•	A previsão de investimentos até 2030 é de R$ 100 bi no Programa de Desenvolvimento de Geração Distribuída. Esse aporte pode resultar em 23,5 mil MW médios de energia limpa e renovável fornecida diretamente às distribuidoras"
                ]
            },
            {
                "title": "Sumário Executivo",
                "imagem": "document-428334_1280.jpg",
                "content": [
                    "Em agosto de 2017, a Companhia recebeu investimentos para suportar seu plano de negócios. Esse recurso será aplicado em unidades geradoras de energia elétrica, priorizando as Centrais Geradoras Hidrelétricas (CGHs). A estrutura de investimento propõe a participação de 85% de investidores estratégicos no capital da Lacus. A Ergos permanece com 15% e a Lacus, por sua vez, vai deter 100% da participação nas Unidades Geradoras de Energia."
                ]
            },
            {
                "title": "A Companhia",
                "imagem": "Solar_Campo.jpg",
                "content": [
                    "A Lacus lida com microgeração e minigeração distribuída de energia elétrica. Essas inovações podem aliar redução de custo, consciência socioambiental e autossustentabilidade.",
                    "Redução de custos, competitividade, foco no mercado cativo e crescimento são os principais pilares da Lacus Energia que, impulsionada pela experiência de seus executivos no setor elétrico, vai trabalhar para maximizar os resultados de seus investidores."
                ]
            },
            {
                "title": "Tese de Investimento",
                "imagem": "document-428338_1280.jpg",
                "content": [
                    "•	Recursos destinados para a aquisição de unidades geradoras de energia",
                    "•	Contratos de 5 a 10 anos com cada cliente",
                    "•	Grandes consumidores de energia de baixa tensão no mercado cativo",
                    "•	Rentabilidade atraente para investidores interessados em ingressar no setor de energia brasileiro",
                    "•	Aumento do consumo de energia elétrica no Brasil",
                    "•	Target de aproximadamente 50 clientes em 10 anos",
                    "•	Momento de fortalecimento e crescimento da geração distribuída no Brasil"
                ]
            },
            {
                "title": "Estratégia do Negócio",
                "imagem": "dollar-exchange-rate-544949_1280.jpg",
                "content": [
                    "O modelo de negócio da Lacus se baseia na possibilidade de autoconsumo remoto de energia elétrica por consumidores conectados na baixa tensão do mercado cativo: ",
                    "•	A Lacus desenvolve ou adquire usinas hídricas, eólicas ou solares que atendem aos parâmetros estabelecidos na Resolução 482 da ANEEL e suas alterações;",
                    "•	Na condição de proprietária desses ativos, a Companhia aluga a unidade geradora para um grande consumidor cativo ou um consórcio de consumidores cativos, proporcionando economia no custo com energia elétrica."
                ]
            }
        ];

    }

})();