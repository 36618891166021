(function () {
    'use strict';

    angular.module('lacus').factory('FocusService', FocusService);
    FocusService.$inject = [
        '$rootScope',
        '$timeout'
    ];

    function FocusService($rootScope, $timeout) {
        return function (name) {
            $timeout(function () {
                $rootScope.$broadcast('focusOn', name);
            });
        };
    }
})();