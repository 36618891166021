(function () {
    'use strict';

    angular.module('lacus').controller('AdminController', AdminController);

    AdminController.$inject = [
        'UsersService',
        '$state',
        'AuthService',
        'SelecaoReferenciaService'
    ];

    function AdminController(
        UsersService,
        $state,
        AuthService,
        SelecaoReferenciaService
    ) {

        var admin = this;

        admin.manageFiles = function (uid) {
            $state.go('user', { uid: uid });
        };

        admin.sair = function () {
            AuthService.$signOut();
            $state.go('home');
        };

        admin.showFaturas = function (user, ev) {
            SelecaoReferenciaService(ev)
                .then(function (referencia) {
                    $state.go('faturas', {
                        uid: user.uid,
                        referencia: referencia
                    });
                });
        };

        admin.showInstalacoes = function (uid) {
            $state.go('filiais', {
                uid: uid
            });
        };

        (function inicializar() {

            AuthService.getUser().then(function (user) {
                admin.user = user;
                admin.users = UsersService();
            }).catch(function () {
                $state.go('login', { route: 'admin' });
            });

        })();
    }

})();